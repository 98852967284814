import { navigateToCustomPage } from '../utils/navigate';
import { useEffect } from 'react';

const IndexPage = () => {
  useEffect(() => {
    navigateToCustomPage('/company/287634/es-ES/error/')?.();
  }, []);

  return null;
};

export default IndexPage;
